<template>
  <div class="contenu-tab-users" ref="contenu">
    <loader v-if="loading" />
    <div class="body-box-rapport scroll-bar" id="filiale-box-body">
      <div class="col-sm-12 p-0">
        <form @submit.prevent="submitFactureLibre">
          <div class="table-rapport-style">
            <div class="row ml-1">
              <b-form-group
                label="Type"
                label-for="Type"
                class="input-modal-champ p-1 col-3 "
              >
                <b-form-select
                  id="type"
                  v-model="facture.type"
                  required
                  :options="computed_filtre_type_facture"
                  class="b-form-select-raduis "
                  disabled
                ></b-form-select>
              </b-form-group>
              <!-- <b-form-group
                label="N°"
                label-for="numéro de facture"
                class="input-modal-champ  p-1 col-3"
              >
                <b-form-input
                  id="num"
                  v-model="facture.num"
                  disabled
                ></b-form-input>
              </b-form-group> -->
              <b-form-group
                label="Date de création"
                label-for="Date de création"
                class="input-modal-champ p-1 col-3"
              >
                <b-form-input
                  type="date"
                  id="dateCreation"
                  v-model="facture.date_creation"
                  required
                  :max="maxDate"
                  @change="getErrorsOfCreatFacture"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Lieu de création"
                label-for="Lieu de création"
                class="input-modal-champ  p-1 col-2"
              >
                <b-form-input
                  id="lieu_creation"
                  v-model="facture.lieu_creation"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label=""
                label-for="categorie"
                class="input-modal-champ p-1 col-4"
              >
                <div class="d-flex ">
                  <p class="mb-2">Catégorie</p>
                  <button
                    v-if="facture && facture.type !== 'Facture d\'avoir'"
                    type="button"
                    v-b-modal.addCategorieModal
                    class="ml-2 btn-add-tva-unite"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    />
                  </button>
                </div>
                <b-form-select
                  id="categorie"
                  v-model="facture.categorie_id"
                  :options="categorieList"
                  class="b-form-select-raduis"
                  text-field="nom_categorie"
                  value-field="id"
                ></b-form-select>
              </b-form-group>
            </div>
            <div
              v-if="facture && facture.type === 'Facture d\'avoir'"
              class="row ml-1"
            >
              <b-form-group
                label="N° du document corrigé "
                label-for="N° du document corrigé "
                class="input-modal-champ col-5"
              >
                <b-form-input
                  disabled
                  v-click-outside="onClickOutsideNumDoc"
                  @input="filterResultsNumDoc(facture.numero_document_corrige)"
                  id="numero_document_corrige"
                  autocomplete="off"
                  v-model="facture.numero_document_corrige"
                  required
                ></b-form-input>
                <div>
                  <ul
                    v-if="resultsListAvoir && isOpenDocumentList"
                    class="autocomplete-results list-unstyled list_satyle_num_avoir"
                  >
                    <li
                      v-for="result in resultsListAvoir"
                      :key="result"
                      @click="setResultsListAvoir(result)"
                    >
                      {{ result }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
              <b-form-group
                label="Motif de l'avoir"
                label-for="Motif de l'avoir"
                class="input-modal-champ p-1 col-7"
              >
                <b-form-input
                  id="motif-doc-corriger"
                  v-model="facture.motif_avoir"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <hr />
          <div class="col-lg-5 table-rapport-style">
            <div class="m-1">
              <br />
              <div class="row">
                <b-form-group
                  class="mr-3 mb-0 col-12"
                  v-if="facture && facture.vendeur && facture.vendeur.idOrigine"
                >
                  <!-- <b-form-select
                    id="type_vendeur"
                    v-model="facture.vendeur.idOrigine"
                    required
                    :options="professionnelSocite"
                    class="b-form-select-raduis "
                    text-field="name"
                    value-field="id"
                    @change="vendeurSelected()"
                  ></b-form-select> -->
                  <b-form-select
                    id="type_vendeur"
                    v-model="facture.vendeur.idOrigine"
                    required
                    class="b-form-select-raduis mr-3 p-1 mb-0 col-12"
                    @change="vendeurSelected()"
                    :disabled="facture.type === 'Facture d\'avoir'"
                  >
                    <b-form-select-option
                      v-for="option in getFiliaeOfResponsable"
                      :key="option.id"
                      :value="option.id"
                      :disabled="option.abreviation === null"
                      >{{ option.name
                      }}{{
                        option.abreviation === null
                          ? " (pas d'abréviation)"
                          : ''
                      }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Nom"
                  label-for="Nom vendeur"
                  class="input-modal-champ col-6"
                >
                  <b-form-input
                    v-if="facture && facture.vendeur"
                    id="nom_compagnie_vendeur"
                    v-model="facture.vendeur.nom_compagnie"
                    required
                    autocomplete="off"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Type Société"
                  label-for="Nom vendeur"
                  class="nput-modal-champ col-6"
                  v-if="facture && facture.vendeur"
                >
                  <b-form-select
                    id="type_societe_vendeur"
                    v-model="facture.vendeur.type_societe"
                    required
                    autocomplete="off"
                    :options="typeSocieteOptions"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="row mb-0">
                <b-form-group class="input-modal-champ col-3 pr-0 mb-0">
                  <b-form-select
                    v-if="facture && facture.vendeur"
                    id="num_tva_siren_vendeur"
                    v-model="facture.vendeur.siren_tva"
                    :options="num_tva_siren"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0 "
                  v-if="
                    facture &&
                      facture.vendeur &&
                      facture.vendeur.siren_tva === 'Numéro TVA'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.vendeur"
                    id="siren_value_vendeur "
                    v-model="facture.vendeur.tva_value"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0 "
                  v-if="
                    facture &&
                      facture.vendeur &&
                      facture.vendeur.siren_tva === 'SIREN'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.vendeur"
                    id="siren_tva_value "
                    v-model="facture.vendeur.siren_value"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-form-group
                label="N° et nom de rue"
                label-for="N° et nom de rue"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  v-if="facture && facture.vendeur && facture.vendeur.adresse"
                  id="adress_vendeur"
                  v-model="facture.vendeur.adresse"
                  autocomplete="off"
                ></b-form-textarea>
              </b-form-group>
              <div class="row m-0">
                <b-form-group
                  label="Code Postal"
                  label-for="Code Postal"
                  class="input-modal-champ col-3 mb-0 pl-0 "
                >
                  <b-form-input
                    v-if="facture && facture.vendeur"
                    id="code_postal_vendeur"
                    v-model="facture.vendeur.code_postal"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Ville"
                  label-for="Ville"
                  class="input-modal-champ col-9 mb-0 pr-0"
                >
                  <b-form-input
                    v-if="facture && facture.vendeur"
                    id="ville_vendeur"
                    v-model="facture.vendeur.ville"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </div>

              <button
                v-if="!hideVendeur"
                class="more_less"
                @click="showMoreVendeur"
                type="button"
              >
                plus <font-awesome-icon icon="angle-down" class="mt-1" />
              </button>

              <div v-if="hideVendeur">
                <b-form-group
                  label="Pays"
                  label-for="Pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    id="pays"
                    v-model="facture.vendeur.pays"
                    class="b-form-select-raduis"
                    :options="uniqueArrayOfCountries"
                    text-field="nom"
                    value-field="nom"
                  ></b-form-select>
                </b-form-group>
                <!-- <div class="row">
                  <b-form-group
                    label="Banque"
                    label-for="Banque"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="banque"
                      v-model="facture.vendeur.banque"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="BIC"
                    label-for="Swift"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="swift"
                      v-model="facture.vendeur.swift"
                      @input="onChangeSwiftVendeur"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="IBAN"
                  label-for="IBAN"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    id="iban"
                    v-model="facture.vendeur.iban"
                    autocomplete="off"
                    @input="onChangeIbanVendeur"
                    required
                  ></b-form-input>
                </b-form-group> -->
                <b-form-group
                  label="Siret"
                  label-for="IBAN"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    id="siret"
                    v-model="facture.vendeur.siret"
                    required
                  ></b-form-input>
                </b-form-group>

                <div class="row ">
                  <b-form-group
                    label="Adresse email"
                    label-for="Adress Mail"
                    class="input-modal-champ col-6 mb-0 "
                  >
                    <b-form-input
                      id="email"
                      v-model="facture.vendeur.email"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Site intenet"
                    label-for="Site intenet"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="site_internet"
                      v-model="facture.vendeur.site_internet"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group
                    label="Fax"
                    label-for="Fax"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="fax"
                      v-model="facture.vendeur.fax"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Téléphone"
                    label-for="Téléphone"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="tel"
                      v-model="facture.vendeur.telephone"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Description additionnelle"
                  label-for="description_additionnelle"
                  class="input-modal-champ pr-1 pl-1 m-0"
                >
                  <b-form-textarea
                    id="description"
                    rows="3"
                    v-model="facture.vendeur.description"
                  ></b-form-textarea>
                </b-form-group>

                <button
                  v-if="hideVendeur"
                  class="more_less"
                  @click="showMoreVendeur"
                  type="button"
                >
                  moins <font-awesome-icon icon="angle-up" class="m-0" />
                </button>
              </div>
            </div>
          </div>
          <div
            class=" col-lg-5 ml-5 table-rapport-style"
            v-if="facture && facture.acheteur"
          >
            <div>
              <h5>Client</h5>
              <b-form-group
                class="mb-0 ml-5"
                label-cols-sm="1"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-if="facture && facture.acheteur && facture.acheteur.type"
                  id="radio-group-1"
                  v-model="facture.acheteur.type"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <div class="row" v-if="facture.acheteur.type === 'professionnel'">
                <b-form-group
                  label="Nom"
                  label-for="Nom acheteur"
                  class="input-modal-champ col-6"
                >
                  <b-form-input
                    v-click-outside="onClickOutside"
                    @input="filterResults(facture.acheteur.nom_compagnie)"
                    id="nom_compagnie_acheteur"
                    autocomplete="off"
                    v-model="facture.acheteur.nom_compagnie"
                    required
                  ></b-form-input>
                  <div>
                    <ul
                      v-if="results && isOpen"
                      class="autocomplete-results list-unstyled list_satyle"
                    >
                      <li
                        v-for="result in results"
                        :key="result"
                        @click="setResults(result)"
                      >
                        {{ result }}
                      </li>
                    </ul>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Type Société"
                  label-for="Nom vendeur"
                  class="nput-modal-champ col-6 "
                >
                  <b-form-select
                    id="type_societe_acheteur"
                    v-model="facture.acheteur.type_societe"
                    autocomplete="off"
                    :options="typeSocieteOptions"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </div>

              <div v-if="facture.acheteur.type === 'particulier'" class="row">
                <b-form-group
                  label="Civilite"
                  label-for="civilite"
                  class=" input-modal-champ col-2 pr-0 m-0"
                >
                  <b-form-select
                    id="genre"
                    v-model="facture.acheteur.civilite"
                    :options="genreList"
                    required
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Prénom"
                  label-for="prénom"
                  class="input-modal-champ col-4  pl-1 m-0"
                >
                  <b-form-input
                    v-click-outside="onClickOutside"
                    id="prenom"
                    v-model="facture.acheteur.prenom"
                    required
                    autocomplete="off"
                    @input="filterPrenomAcheteur(facture.acheteur.prenom)"
                  ></b-form-input>
                  <div>
                    <ul
                      v-if="
                        resultsPrenomNomFamille &&
                          isOpenAcheteurParticulierPrenomList
                      "
                      class="autocomplete-results list-unstyled list_satyle_prenom"
                    >
                      <li
                        v-for="result in filialsListParticuluerNames"
                        :key="result"
                        @click="setResultsPrenomAcheteur(result)"
                      >
                        {{ result }}
                      </li>
                    </ul>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Nom de famille"
                  label-for="Nom de famille"
                  class="input-modal-champ col-6  pl-1 m-0"
                >
                  <b-form-input
                    id="nom_famille"
                    v-model="facture.acheteur.nom_famille"
                    required
                    autocomplete="off"
                    @input="
                      filterNomFamilleAcheteur(facture.acheteur.nom_famille)
                    "
                  ></b-form-input>
                  <div>
                    <ul
                      v-if="
                        resultsPrenomNomFamille &&
                          isOpenAcheteurParticulierNomFamilleList
                      "
                      class="autocomplete-results list-unstyled list_satyle_prenom"
                    >
                      <li
                        v-for="result in filialsListParticuluerNames"
                        :key="result"
                        @click="setResultsNomFamilleAcheteur(result)"
                      >
                        {{ result }}
                      </li>
                    </ul>
                  </div>
                </b-form-group>
              </div>
              <div class="row mb-0 mt-3" v-if="facture.acheteur.type">
                <b-form-group class="input-modal-champ col-3 pr-0 mb-0">
                  <b-form-select
                    id="num_tva_siren_acheteur"
                    v-model="facture.acheteur.siren_tva"
                    required
                    :options="num_tva_siren"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0 "
                  v-if="
                    facture &&
                      facture.acheteur &&
                      facture.acheteur.siren_tva === 'Numéro TVA'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.acheteur"
                    id="siren_value_acheteur "
                    v-model="facture.acheteur.tva_value"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="input-modal-champ col-9 mb-0 "
                  v-if="
                    facture &&
                      facture.acheteur &&
                      facture.acheteur.siren_tva === 'SIREN'
                  "
                >
                  <b-form-input
                    v-if="facture && facture.acheteur"
                    id="siren_value_ach "
                    v-model="facture.acheteur.siren_value"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-form-group
                label="N° et nom de rue"
                label-for="N° et nom de rue"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  v-if="facture && facture.acheteur"
                  id="adress_acheteur"
                  v-model="facture.acheteur.adresse"
                  required
                ></b-form-textarea>
              </b-form-group>
              <div class="row m-0">
                <b-form-group
                  label="Code Postal"
                  label-for="Code Postal"
                  class="input-modal-champ col-3 mb-0 pl-0 "
                >
                  <b-form-input
                    id="code_postal_acheteur"
                    v-model="facture.acheteur.code_postal"
                    required
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Ville"
                  label-for="Ville"
                  class="input-modal-champ col-9 mb-0 pr-0"
                >
                  <b-form-input
                    id="ville_acheteur"
                    v-model="facture.acheteur.ville"
                    required
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </div>
              <button
                v-if="!hideAcheteur"
                class="more_less"
                @click="showMoreAcheteur"
                type="button"
              >
                plus <font-awesome-icon icon="angle-down" class="mt-1" />
              </button>
              <div v-if="hideAcheteur">
                <b-form-group
                  label="Pays"
                  label-for="Pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    id="pays"
                    v-model="facture.acheteur.pays"
                    :options="uniqueArrayOfCountries"
                    class="b-form-select-raduis"
                    text-field="nom"
                    value-field="nom"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Description additionnelle"
                  label-for="description_additionnelle"
                  class="input-modal-champ pr-1 pl-1 m-0"
                >
                  <b-form-textarea
                    id="description"
                    rows="3"
                    v-model="facture.acheteur.description"
                  ></b-form-textarea>
                </b-form-group>
                <!-- <div class="row">
                  <b-form-group
                    label="Banque"
                    label-for="Banque"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="banque"
                      v-model="facture.acheteur.banque"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="BIC"
                    label-for="Swift"
                    class="input-modal-champ col-6 mb-0"
                  >
                    <b-form-input
                      id="swift"
                      v-model="facture.acheteur.swift"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="IBAN"
                  label-for="Nom de famille"
                  class="input-modal-champ pl-1 m-0"
                >
                  <b-form-input
                    id="iban_acheteur"
                    v-model="facture.acheteur.iban"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Siret"
                  label-for="iban"
                  class="input-modal-champ pl-1 m-0"
                >
                  <b-form-input
                    id="siret_acheteur"
                    v-model="facture.acheteur.siret"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group> -->

                <b-form-group
                  label="Adresse(s) Email"
                  label-for="Adress_email"
                  class="input-modal-champ m-0"
                >
                  <b-form-input
                    id="email"
                    v-model="facture.acheteur.email"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
                <div class="row m-0">
                  <b-form-group
                    label="Téléphone"
                    label-for="tel"
                    class="input-modal-champ col-6 p-0 m-0"
                  >
                    <b-form-input
                      id="fax"
                      v-model="facture.acheteur.telephone"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Téléphone portable"
                    label-for="tel_portable"
                    class="input-modal-champ p-0 col-6 m-0"
                  >
                    <b-form-input
                      id="tel_portable"
                      v-model="facture.acheteur.telephone_portable"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <button
                  v-if="hideAcheteur"
                  class="more_less"
                  @click="showMoreAcheteur"
                  type="button"
                >
                  moins <font-awesome-icon icon="angle-up" class="m-0" />
                </button>
              </div>
            </div>
          </div>
          <div class=" mb-1 mt-2 col-6 table-rapport-style background-style">
            <b-form-group
              label=""
              label-for="Objet"
              rows="1"
              class="input-modal-champ pr-0 pb-2 pl-1 m-0 "
            >
              <div class="d-flex">
                <p>Objet</p>
              </div>
              <b-form-textarea
                rows="1"
                id="objet"
                v-model="facture.objet"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <hr />
          <div class="card mb-5">
            <div class="card-header p-1 text-dark">
              Désignation
              <button
                type="button"
                v-if="showDep"
                class="btn btn-outline-secondary mr-2 p-1 float-right"
                @click="hideDepot"
              >
                Cacher Dépot
              </button>
              <button
                type="button"
                v-if="!showDep"
                class="btn btn-outline-secondary mr-2 p-1 float-right"
                @click="showDepot"
              >
                Ajouter Dépot
              </button>
              <button
                type="button"
                v-if="showReduc"
                class="btn btn-outline-secondary  mr-2 p-1 float-right"
                @click="hideReduction"
              >
                Cacher Réduction
              </button>
              <button
                type="button"
                v-if="
                  !showReduc && facture && facture.type !== 'Facture d\'avoir'
                "
                class="btn btn-outline-secondary mr-2 p-1 float-right"
                @click="showReduction"
              >
                Ajouter Réduction
              </button>
            </div>
            <div v-if="facture && facture.produits" class="card-body w-100">
              <div class="row text-dark">
                <p
                  class="col-lg-1 mb-1 "
                  v-if="facture && facture.type === 'Facture d\'avoir'"
                ></p>
                <p
                  :class="
                    facture && facture.type === 'Facture d\'avoir'
                      ? 'input-modal-champ col-lg-1 ml-3 p-0 mb-1'
                      : 'input-modal-champ col-lg-2 ml-3 p-0 mb-1'
                  "
                >
                  Nom
                </p>
                <p class="col-lg-1 pt-pr-pb-0 mb-1 font-tabel-produit">Réf.</p>
                <p class="col-lg-1 mb-1 font-tabel-produit" v-if="showDep">
                  Dépôt
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">Qté</p>
                <p class="col-lg-1 mb-1 font-tabel-produit">
                  Unité
                  <button
                    class="ml-3 btn-add-tva-unite"
                    type="button"
                    v-b-modal.addUnitModal
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    />
                  </button>
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">PU HT</p>
                <p
                  class="col-lg-1 mb-1 font-tabel-produit"
                  v-if="
                    showReduc &&
                      facture &&
                      facture.type !== 'Facture d\'avoir' &&
                      facture.comment_calculer_facture === 'montant'
                  "
                >
                  Red. Mt
                </p>
                <p
                  class="col-lg-1 mb-1 font-tabel-produit"
                  v-if="
                    showReduc &&
                      facture &&
                      facture.type !== 'Facture d\'avoir' &&
                      facture.comment_calculer_facture === 'pourcentage'
                  "
                >
                  Red. %
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">Net HT</p>
                <p class="col-lg-1 mb-1 mr-2 font-tabel-produit">
                  TVA %<button
                    type="button"
                    v-b-modal.addtvaModal
                    class="ml-3 btn-add-tva-unite"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    />
                  </button>
                </p>
                <p class="col-lg-1 mb-1 font-tabel-produit">
                  Total TTC
                </p>
              </div>
              <draggable :list="facture.produits">
                <div
                  v-for="(produit, index) in facture.produits"
                  :key="index"
                  class=" ml-2 text-dark"
                >
                  <p class="m-0" hidden>{{ (produit.index = index + 1) }}</p>
                  <template v-if="produit.type === 'produit'">
                    <div class="row">
                      <div
                        v-if="facture && facture.type === 'Facture d\'avoir'"
                      >
                        <b>Avant </b>
                        <br />
                        <p>correction</p>
                      </div>
                      <b-form-group
                        :class="
                          facture && facture.type === 'Facture d\'avoir'
                            ? 'input-modal-champ col-lg-1 ml-3 p-0 mb-1'
                            : 'input-modal-champ col-lg-2 ml-3 p-0 mb-1'
                        "
                      >
                        <b-form-input
                          :id="'product' + index"
                          :disabled="
                            facture && facture.type === 'Facture d\'avoir'
                          "
                          v-model="produit.nom"
                          v-click-outside="onClickOutsideProduct"
                          @input="
                            filterResultsProduit(produit.nom, index, 'avant')
                          "
                          autocomplete="off"
                          required
                        ></b-form-input>
                        <div>
                          <ul
                            :key="'produitUl' + index"
                            v-if="resultsProduits && produit.isOpenProduitList"
                            class="autocomplete-results list-unstyled list_style_products"
                          >
                            <li
                              v-for="result in resultsProduits"
                              :key="result"
                              @click="setResultsProduct(result, produit)"
                            >
                              {{ result }}
                            </li>
                          </ul>
                        </div>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0 mb-1">
                        <b-form-input
                          :disabled="
                            facture && facture.type === 'Facture d\'avoir'
                          "
                          id="reference"
                          v-model="produit.reference"
                          required
                          autocomplete="off"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="showDep"
                        class="input-modal-champ col-lg-1 p-0 mb-1"
                      >
                        <b-form-input
                          id="depot"
                          v-model="produit.depot"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group class="input-modal-champ col-lg-1 mb-1 p-0">
                        <b-form-input
                          @input="claculeTotal_Ht_Ttc(produit)"
                          id="qte"
                          v-model="produit.qte"
                          type="number"
                          class="padding-input-number"
                          step="any"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-select
                          id="unite"
                          v-model="produit.unite"
                          :options="getAllUnites"
                          class="b-form-select-raduis "
                          text-field="valeur"
                          value-field="valeur"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 mb-1 p-0">
                        <b-form-input
                          @input="claculeTotal_Ht_Ttc(produit)"
                          id="pu_ht"
                          v-model="produit.pu_ht"
                          required
                          type="number"
                          class="padding-input-number"
                          step="any"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="showReduc"
                        class="input-modal-champ col-lg-1 p-0 mb-1"
                      >
                        <b-form-input
                          id="reduction"
                          v-model="produit.reduction"
                          :max="
                            facture.comment_calculer_facture === 'pourcentage'
                              ? 100
                              : produit.pu_ht
                          "
                          type="number"
                          class="padding-input-number"
                          step="any"
                          @input="change_reduction(produit)"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-input
                          @input="onChangeTotalHt(produit)"
                          id="total_ht"
                          v-model="produit.total_ht"
                          class="padding-input-number"
                          type="number"
                          step="any"
                          required
                          disabled
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-select
                          @change="claculeTotal_Ht_Ttc(produit)"
                          id="tva"
                          v-model="produit.tva"
                          required
                          :options="getAllTvas"
                          class="b-form-select-raduis "
                          text-field="valeur"
                          value-field="valeur"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group class="input-modal-champ col-lg-1 p-0">
                        <b-form-input
                          id="total_ttc"
                          v-model="produit.total_ttc"
                          @input="changeTotalTtc(produit)"
                          class="padding-input-number"
                          type="number"
                          step="any"
                          required
                          disabled
                        ></b-form-input>
                      </b-form-group>
                      <b-button
                        class="button-danger-style ml-2 mr-2 mb-3 mt-0"
                        size="sm"
                        variant="danger"
                        @click="deleteProduct(index)"
                      >
                        X
                      </b-button>
                      <b-form-checkbox
                        v-if="facture && facture.type !== 'Facture d\'avoir'"
                        class="col-5 ml-4"
                        :id="'produit' + index"
                        v-model="produit.isSelectInput"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Ajouter le produit à votre catalogue
                      </b-form-checkbox>
                    </div>
                    <div class="row ">
                      <template
                        v-if="
                          produit.type === 'produit' &&
                            facture &&
                            facture.type === 'Facture d\'avoir'
                        "
                      >
                        <div>
                          <b>Aprés</b>
                          <br />
                          <p>correction</p>
                        </div>

                        <b-form-group
                          class="input-modal-champ col-lg-1 p-0 ml-3 mb-1"
                        >
                          <b-form-input
                            :disabled="
                              facture && facture.type === 'Facture d\'avoir'
                            "
                            :id="'product' + index"
                            v-model="produit.nom"
                            v-click-outside="onClickOutsideProduct"
                            @input="
                              filterResultsProduit(produit.nom, index, 'apres')
                            "
                            autocomplete="off"
                          ></b-form-input>
                          <div>
                            <ul
                              :key="'produitUl' + index"
                              v-if="
                                resultsProduits &&
                                  produit.isOpenProduitList_apres
                              "
                              class="autocomplete-results list-unstyled list_style_products"
                            >
                              <li
                                v-for="result in resultsProduits"
                                :key="result"
                                @click="setResultsProduct(result, produit)"
                              >
                                {{ result }}
                              </li>
                            </ul>
                          </div>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                          <b-form-input
                            :disabled="
                              facture && facture.type === 'Facture d\'avoir'
                            "
                            id="reference"
                            v-model="produit.reference"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="showDep"
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                          <b-form-input
                            id="depot"
                            v-model="produit.depot_apres"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-lg-1 mb-1 p-0"
                        >
                          <b-form-input
                            @input="claculeTotal_Ht_Ttc_apres(produit)"
                            id="qte"
                            v-model="produit.qte_apres"
                            class="padding-input-number"
                            type="number"
                            step="any"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-select
                            id="unite"
                            v-model="produit.unite_apres"
                            :options="getAllUnites"
                            class="b-form-select-raduis "
                            text-field="valeur"
                            value-field="valeur"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-lg-1 mb-1 p-0"
                        >
                          <b-form-input
                            @input="claculeTotal_Ht_Ttc_apres(produit)"
                            id="pu_ht"
                            v-model="produit.pu_ht_apres"
                            step="any"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="showReduc"
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                          <b-form-input
                            id="reduction"
                            v-model="produit.reduction_apres"
                            class="padding-input-number"
                            type="number"
                            step="any"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-input
                            @input="claculeTotal_Ht_Ttc_apres(produit)"
                            id="total_ht!apres"
                            v-model="produit.total_ht_apres"
                            disabled
                            step="any"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-select
                            @change="claculeTotal_Ht_Ttc(produit)"
                            id="tva"
                            v-model="produit.tva_apres"
                            required
                            :options="getAllTvas"
                            class="b-form-select-raduis "
                            text-field="valeur"
                            value-field="valeur"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-input
                            id="total_ttc_apres"
                            v-model="produit.total_ttc_apres"
                            @input="changeTotalTtcApres(produit)"
                            disabled
                            step="any"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                      </template>
                    </div>
                    <div class="row ">
                      <template
                        v-if="
                          produit.type === 'produit' &&
                            facture &&
                            facture.type === 'Facture d\'avoir'
                        "
                      >
                        <div>
                          <p>
                            sous-total<br />
                            Avoir
                          </p>
                        </div>
                        <b-form-group
                          class="input-modal-champ col-lg-1 ml-3  p-0"
                        >
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                        </b-form-group>
                        <b-form-group
                          v-if="showDep"
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-lg-1 mb-1 p-0"
                        >
                          <b-form-input
                            disabled
                            :value="computed_qte_sous_total_avoir(produit)"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-lg-1 mb-1 p-0"
                        >
                          <b-form-select
                            v-model="produit.unite_apres"
                            :options="getAllUnites"
                            class="b-form-select-raduis "
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-lg-1 mb-1 p-0"
                        >
                          <b-form-input
                            disabled
                            id="pu_ht"
                            :value="computed_pu_ht_sous_total_avoir(produit)"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="showReduc"
                          class="input-modal-champ col-lg-1 p-0 mb-1"
                        >
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-input
                            disabled
                            :value="computed_total_ht_sous_total_avoir(produit)"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="!produit.isSelectInputTva_apres"
                          class="input-modal-champ col-lg-1 p-0"
                        >
                        </b-form-group>
                        <b-form-group
                          v-if="produit.isSelectInputTva_apres"
                          class="input-modal-champ col-lg-1 p-0"
                        >
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-lg-1 p-0">
                          <b-form-input
                            disabled
                            id="total_ttc"
                            :value="
                              computed_total_ttc_sous_total_avoir(produit)
                            "
                          ></b-form-input>
                        </b-form-group>
                      </template>
                    </div>

                    <b-form-group class="input-modal-champ w-100 ">
                      <b-form-textarea
                        placeholder="Description"
                        id="description"
                        v-model="produit.description"
                      ></b-form-textarea>
                    </b-form-group>
                  </template>

                  <template v-if="produit.type === 'sous_total'"
                    ><div class="row">
                      <b-form-group class="input-modal-champ col-11 ml-2 p-0">
                        <b-form-input
                          disabled
                          :value="
                            'Sous total Net Ht : ' +
                              computedSumNetHtBeforSt(index)
                          "
                        ></b-form-input>
                      </b-form-group>
                      <b-button
                        class="button-danger-style ml-0  mr-2 mb-3 mt-0"
                        size="sm"
                        variant="danger"
                        @click="deleteProduct(index)"
                      >
                        X
                      </b-button>
                    </div>
                  </template>
                  <template v-if="produit.type === 'saut_page'">
                    <div class="row">
                      <b-form-group class="input-modal-champ col-11 ml-2 p-0">
                        <b-form-input
                          v-model="sautPage"
                          disabled
                        ></b-form-input>
                      </b-form-group>
                      <b-button
                        class="button-danger-style ml-0 mr-2 mb-3 mt-0"
                        size="sm"
                        variant="danger"
                        @click="deleteProduct(index)"
                      >
                        X
                      </b-button>
                    </div>
                  </template>
                  <template v-if="produit.type === 'ligne_text'">
                    <div class="row">
                      <b-form-group class="input-modal-champ col-11 ml-2 p-0">
                        <b-form-textarea
                          class="text-line"
                          rows="1"
                          v-model="produit.contenu"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-button
                        class="button-danger-style ml-0 mr-2 mb-3 mt-0"
                        size="sm"
                        variant="danger"
                        @click="deleteProduct(index)"
                      >
                        X
                      </b-button>
                    </div>
                  </template>
                </div>
              </draggable>
            </div>
            <div class="row ml-2">
              <b-button
                v-if="facture && facture.type !== 'Facture d\'avoir'"
                variant="success"
                class="config-btn ml-2 col-1 p-0 mb-1"
                @click="addNewProduct"
              >
                <font-awesome-icon icon="plus" /> Produit
              </b-button>
              <b-button
                v-if="facture && facture.type !== 'Facture d\'avoir'"
                variant="light"
                class="config-btn ml-2 col-2 mb-1 button-produit"
                @click="addNewTextLine"
              >
                <font-awesome-icon icon="plus" /> Ligne de texte
              </b-button>
              <b-button
                v-if="facture && facture.type !== 'Facture d\'avoir'"
                variant="light"
                class="config-btn ml-2 col-2 mb-1 p-0 button-produit"
                @click="addNewSautPage"
              >
                <font-awesome-icon icon="plus" /> Saut de page
              </b-button>
              <b-button
                v-if="facture && facture.type !== 'Facture d\'avoir'"
                variant="light"
                class="config-btn ml-2 col-1 mb-1 p-0 button-produit"
                @click="addNewSousToltal"
              >
                <font-awesome-icon icon="plus" /> Sous-total
              </b-button>
            </div>
          </div>
          <div class="row mb-4  mr-3" v-if="facture && facture.produits">
            <div class="col-lg-8"></div>
            <div v-if="facture && facture.devise" class="col-lg-4">
              <table class="table  ">
                <tbody>
                  <tr class="m-2">
                    <td><b>Total HT</b></td>
                    <td class="text-right pr-3">
                      {{ (Math.round(totalHt * 100) / 100).toFixed(2) || 0 }}
                    </td>
                    <td>
                      <b-form-group class="input-modal-champ m-0 p-0">
                        <b-form-select
                          id="devise_table"
                          v-model="facture.devise"
                          :options="currencyList"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </td>
                  </tr>
                  <template>
                    <td class="td-width"><b>Total remise </b></td>
                    <td class="text-right pr-3">
                      {{
                        (Math.round(totalReduction * 100) / 100).toFixed(2) || 0
                      }}
                    </td>
                    <td>{{ facture.devise }}</td></template
                  >
                  <tr>
                    <td><b>Net HT</b></td>
                    <td class="text-right pr-3">
                      {{ (Math.round(NetHt * 100) / 100).toFixed(2) || 0 }}
                    </td>
                    <td>{{ facture.devise }}</td>
                  </tr>
                  <tr>
                    <td><b>Total TVA</b></td>
                    <td class="text-right pr-3">
                      {{ total_tva.toFixed(2) || 0 }}
                    </td>
                    <td class="ml-3">{{ facture.devise }}</td>
                  </tr>
                  <tr>
                    <td><b>Total TTC</b></td>
                    <td class="text-right pr-3">
                      {{ (Math.round(totalTtc * 100) / 100).toFixed(2) || 0 }}
                    </td>
                    <td>{{ facture.devise }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="background-style p-3 m-2">
            <div class="row  ">
              <b-form-group
                label="Mode de règlement"
                label-for="Mode de règlement"
                class="input-modal-champ m-0 col-3"
              >
                <b-form-select
                  id="mode_reglement"
                  v-model="facture.mode_reglement"
                  required
                  :options="modeReglementList"
                  class="b-form-select-raduis "
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Conditions de paiement "
                label-for="Date limite de règlement"
                class="input-modal-champ m-0 col-3"
              >
                <b-form-select
                  id="date_limite_reglement"
                  v-model="facture.date_limite_reglement"
                  required
                  :options="dateLimitReglementList"
                  class="b-form-select-raduis "
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="facture.date_limite_reglement === 'Date choisie'"
                class="input-modal-champ m-0 col-2 mt-4 p-0"
              >
                <b-form-input
                  type="date"
                  id="date_exact_limite_reglement"
                  class="input-modal-champ "
                  v-model="facture.date_exact_limite_reglement"
                />
              </b-form-group>
              <b-form-group
                label="Etat "
                label-for="Etat"
                class="input-modal-champ m-0 col-2"
              >
                <b-form-select
                  id="etat"
                  v-model="facture.etat"
                  required
                  :options="etatList"
                  class="b-form-select-raduis "
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Montant payé"
                label-for="Montant payé"
                class="input-modal-champ  col-2"
              >
                <b-form-input
                  id="montant_payer"
                  v-model="facture.montant_payer"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div v-if="showReduc" class="row">
              <b-form-group
                label="Comment calculer la réduction "
                label-for="Comment calculer la réduction"
                class="input-modal-champ  col-3"
              >
                <b-form-select
                  id="comment_calculer_facture"
                  v-model="facture.comment_calculer_facture"
                  required
                  :options="calculeReduction"
                  class="b-form-select-raduis "
                  @change="onChangeCommentCalculer"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="facture.comment_calculer_facture !== 'montant'"
                label="Ajouter une réduction globale (%)"
                label-for="Ajouter une réduction globale ( %)"
                class="input-modal-champ col-3 "
              >
                <b-form-input
                  id="ajout_reduction_global"
                  v-model="facture.ajout_reduction_global"
                  class="padding-input-number"
                  type="number"
                  max="100"
                  @input="
                    onChangeGlobalReduction(facture.ajout_reduction_global)
                  "
                ></b-form-input>
              </b-form-group>
            </div>
            <b-col col xs="12" sm="8" md="8">
              <!-- Rib  -->
              <b-form-group label="Ribs" label-for="rib-template-select">
                <multiselect
                  v-model="rib"
                  :options="getRibOfFilials"
                  :multiple="false"
                  label="rib"
                  track-by="id"
                  required
                  class="multiselect-vue-custom-style-cdg"
                  :showLabels="false"
                  @select="onChangeRibSelect($event)"
                >
                </multiselect>
              </b-form-group>
            </b-col>
            <div class="row">
              <b-form-group
                label="IBAN"
                label-for="iban"
                class="input-modal-champ col-4 "
              >
                <b-form-input
                  id="iban"
                  v-model="facture.iban"
                  disabled
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="SWIFT"
                label-for="swift"
                class="input-modal-champ col-4 "
              >
                <b-form-input
                  id="swift"
                  v-model="facture.swift"
                  disabled
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Banque"
                label-for="Banque"
                class="input-modal-champ col-2 mb-0"
                v-if="facture && facture.vendeur && facture.vendeur.banque"
              >
                <b-form-input
                  id="banque"
                  v-model="facture.vendeur.banque"
                  :disabled="facture.type === 'Facture d\'avoir'"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Devise"
                label-for="devise"
                class="input-modal-champ col-1"
              >
                <b-form-select
                  id="devise_facture"
                  v-model="facture.devise"
                  :options="currencyList"
                  class="b-form-select-raduis "
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Description"
                label-for="description"
                class="input-modal-champ col-12 mb-0"
              >
                <b-form-textarea
                  id="description"
                  v-model="facture.description"
                  autocomplete="off"
                  required
                  :disabled="facture.type === 'Facture d\'avoir'"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <div class="error-message d-flex justify-content-center">
            <div class="error">
              <ul v-if="Array.isArray(error)" class="mb-0">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <hr />
          <b-button class="button-13 mb-1" type="submit">Sauvgarde</b-button>
          <b-button
            class="button-13 mb-1 ml-1  w-25"
            v-b-modal.displayModal
            @click="displayBill"
          >
            Aperçu provisoire</b-button
          >
          ou
          <b-link href="/facture-libre">
            Retour
          </b-link>
          <b-modal
            ref="displayModal"
            id="displayModal"
            no-close-on-backdrop
            :hide-footer="true"
            @hidden="resteTvaUniteModal"
            title="Facture brouillant"
            modal-class="cutsom-modal-bootstrap custom-modal-size-pdf"
          >
            <template>
              <loader v-if="loading" />
              <iframe v-else width="600" height="700" :src="pdfSource"></iframe>
            </template>
          </b-modal>
          <b-modal
            ref="addUnitModal"
            id="addUnitModal"
            no-close-on-backdrop
            :hide-footer="true"
            title="Ajouter Unite"
            modal-class="cutsom-modal-bootstrap"
          >
            <b-form-group
              label="Unité"
              label-for="unite"
              class="input-modal-champ"
            >
              <b-form-input
                id="unite"
                v-model="valeur"
                required
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <div class="error-message d-flex justify-content-center">
              <div class="error">
                <div>{{ errorTvaUnite }}</div>
              </div>
            </div>
            <div class="form-modal-custom-style mt-2">
              <div class="actionModel">
                <b-button
                  class="button-valide-style"
                  @click="addUnite('unité')"
                >
                  <span>
                    Valider
                    <div v-if="loadingTvaUnite" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </b-modal>
          <b-modal
            ref="addtvaModal"
            id="addtvaModal"
            no-close-on-backdrop
            :hide-footer="true"
            @hidden="resteTvaUniteModal"
            title="Ajouter tva"
            modal-class="cutsom-modal-bootstrap"
          >
            <b-form-group
              label="Tva"
              label-for="unite"
              class="input-modal-champ"
            >
              <b-form-input
                id="tva"
                v-model="valeur"
                required
                type="number"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <div class="error-message d-flex justify-content-center">
              <div class="error">
                <div>{{ errorTvaUnite }}</div>
              </div>
            </div>
            <div class="form-modal-custom-style mt-2">
              <div class="actionModel">
                <b-button class="button-valide-style" @click="addTav('tva')">
                  <span>
                    Valider
                    <div v-if="loadingTvaUnite" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </b-modal>
          <b-modal
            ref="addCategorieModal"
            id="addCategorieModal"
            no-close-on-backdrop
            :hide-footer="true"
            @hidden="resteCategorieModal"
            title="Ajouter catégorie"
            modal-class="cutsom-modal-bootstrap"
          >
            <b-form-group
              label="Catégorie"
              label-for="cate"
              class="input-modal-champ"
            >
              <b-form-input
                id="cat"
                v-model="nom_categorie"
                required
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <div class="error-message d-flex justify-content-center">
              <div class="error">
                <div>{{ errorCategorie }}</div>
              </div>
            </div>
            <div class="form-modal-custom-style mt-2">
              <div class="actionModel">
                <b-button class="button-valide-style" @click="addCat()">
                  <span>
                    Valider
                    <div v-if="loadingCategorie" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </b-modal>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import loader from '../Loader.vue';
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
// import InputColorPicker from 'vue-native-color-picker';
export default {
  data() {
    return {
      pdfSource: null,
      errorTvaUnite: null,
      options: [
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' }
      ],
      typeFacture: ['Facture', 'Facture proforma'],
      loadingTvaUnite: false,
      // typeFacture: [
      //   'Facture',
      //   'Facture Proforma',
      //   "Facture d'acompte",
      //   'Facture de solde',
      //   "Facture d'avoir"
      // ],
      results: [],
      typeSocieteOptions: [
        'EURL',
        'SASU',
        'SARL',
        'SA',
        'SAS',
        'SCP',
        'SNC',
        'SCS',
        'SCA',
        'SEL'
      ],
      loadingCategorie: null,
      maxDate: moment(Date.now()).format('YYYY-MM-DD'),
      valeur: null,
      uniteList: [],
      listTva: [],
      sousTotal: 'Sous totale',
      sautPage: 'Saut de page',
      loading: false,
      error: null,
      errorCategorie: null,
      showReduc: false,
      showDep: false,
      rib: null,
      isOpenDocumentList: false,
      hideVendeur: false,
      hideAcheteur: false,
      showUserType: false,
      nom_categorie: null,
      resultsProduits: [],
      resultsListAvoir: [],
      hidePourcentageInput: false,
      categorieList: [],
      productListName: [],
      professionnelSociteVender: [],
      resultsPrenomNomFamille: [],
      professionnelSocite: [],
      particulierSocite: [],
      filialsListParticuluerNames: [],
      isOpenAcheteurParticulierPrenomList: false,
      isOpenAcheteurParticulierNomFamilleList: false,
      genreList: ['M.', 'Mme', 'Mmes', 'Mrs', 'M & Mme'],
      num_tva_siren: ['Numéro TVA', 'SIREN'],
      currencyList: ['EUR', 'USD'],
      modeReglementList: [
        'Virement bancaire',
        'Carte bancaire',
        'Espèses',
        'LCR Lettre de change Relevé',
        'Prélèvement',
        'PayPal',
        'Chèque'
      ],
      dateLimitReglementList: [
        'A réception',
        'A la commande',
        '30 jours fin de mois',
        '45 jours fin de mois',
        '60 jours fin de mois',
        '90 jours fin de mois',
        '30 jours fin de mois le 15 du mois',
        '30 jours fin de décade',
        'Date choisie'
      ],
      etatList: ['Créé', 'Payé', 'Payé en partie'],
      calculeReduction: ['pourcentage', 'montant'],
      currentFacure: {},
      type: null,
      isOpen: false,
      filialsListNames: [],
      uniqueArrayOfCountries: [],
      facture: {}
    };
  },
  components: {
    draggable,
    loader
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters([
      'getSettingFilialesTh',
      'getPaysList',
      'getFactureLibreToUpdate',
      'getProduits',
      'getAllCategories',
      'getUserData',
      'getListFactureForAvoir',
      'getFiliaeOfResponsable',
      'getAllTvas',
      'getAllUnites',
      'getRibOfFilials'
    ]),
    checkPercentUnite() {
      return function(unite) {
        if (unite === '%') {
          return true;
        } else {
          return false;
        }
      };
    },
    computedSumNetHtBeforSt() {
      return function(index) {
        let sum = 0.0;
        for (let i = index - 1; i >= 0; i--) {
          if (this?.facture?.produits[i]?.type == 'produit') {
            sum += this.facture.produits[i].total_ht;
          } else {
            return Math.round(sum * 100) / 100;
          }
        }
        return Math.round(sum * 100) / 100;
      };
    },
    computed_filtre_type_facture() {
      if (this.facture.type === "Facture d'avoir") {
        return this.typeFacture;
      } else {
        return ['Facture', 'Facture proforma'];
      }
    },
    NetHt() {
      let sum = 0.0;
      if (this.facture.type !== "Facture d'avoir") {
        this.facture?.produits?.forEach(element => {
          sum += parseFloat(element.total_ht || 0);
        });
      } else {
        this.facture?.produits?.forEach(element => {
          sum +=
            parseFloat((element?.total_ht_apres + '').replace(',', '.') || 0) -
            parseFloat((element?.total_ht + '').replace(',', '.') || 0);
        });
      }
      return sum.toFixed(2);
    },
    totalHt() {
      let sum = 0.0;
      if (this.facture.type !== "Facture d'avoir") {
        this.facture?.produits?.forEach(element => {
          sum +=
            parseFloat((element.qte + '').replace(',', '.') || 0) *
            (element.unite === '%'
              ? parseFloat((element.pu_ht + '').replace(',', '.') || 0) / 100
              : parseFloat((element.pu_ht + '').replace(',', '.') || 0));
        });
      } else {
        this.facture?.produits?.forEach(element => {
          if (element.qte_apres != null && element.pu_ht_apres != null)
            sum +=
              parseFloat((element.qte_apres + '').replace(',', '.') || 0) *
              (element.unite === '%'
                ? parseFloat(
                    (element.pu_ht_apres + '').replace(',', '.') || 0
                  ) / 100
                : parseFloat(
                    (element.pu_ht_apres + '').replace(',', '.') || 0
                  ));
        });
      }
      return sum;
    },
    total_tva() {
      let sum = 0.0;
      if (this.facture.type !== "Facture d'avoir") {
        this.facture?.produits?.forEach(element => {
          if (element.total_ht !== null && element.tva !== null) {
            sum +=
              Math.round(
                ((parseFloat((element.total_ht + '').replace(',', '.') || 0) *
                  parseFloat((element.tva + '').replace(',', '.') || 0)) /
                  100) *
                  100
              ) / 100;
          }
          // sum += parseFloat((element.total_ht * element.tva) / 100) || 0;
        });
      } else {
        this.facture?.produits?.forEach(element => {
          if (element.total_ht_apres !== null && element.tva_apres !== null) {
            sum +=
              Math.round(
                ((parseFloat(
                  (element.total_ht_apres + '').replace(',', '.') || 0
                ) *
                  parseFloat((element.tva_apres + '').replace(',', '.') || 0)) /
                  100) *
                  100
              ) / 100;
          }
        });
      }
      return sum;
    },
    totalTtc() {
      let sum = 0.0;
      if (this.facture.type !== "Facture d'avoir") {
        this.facture?.produits?.forEach(element => {
          if (element.type === 'produit') {
            sum += parseFloat((element.total_ttc + '').replace(',', '.') || 0);
          }
        });
      } else {
        this.facture?.produits?.forEach(element => {
          sum +=
            parseFloat((element?.total_ttc + '').replace(',', '.') || 0) -
            parseFloat((element?.total_ttc_apres + '').replace(',', '.') || 0);
        });
      }
      return sum;
    },
    totalReduction() {
      let sum = 0.0;
      if (this.facture.type !== "Facture d'avoir") {
        if (this.facture.comment_calculer_facture !== 'montant') {
          this.facture?.produits?.forEach(element => {
            sum +=
              (parseFloat((element.qte + '').replace(',', '.') || 0) *
                (element.unite === '%'
                  ? parseFloat((element.pu_ht + '').replace(',', '.') || 0) /
                    100
                  : parseFloat((element.pu_ht + '').replace(',', '.') || 0)) *
                parseFloat((element.reduction + '').replace(',', '.') || 0)) /
              100;
          });
        } else {
          this.facture?.produits?.forEach(element => {
            element.unite === '%'
              ? (sum +=
                  (parseFloat((element.reduction + '').replace(',', '.') || 0) /
                    100) *
                  parseFloat((element.qte + '').replace(',', '.') || 0))
              : (sum +=
                  parseFloat((element.reduction + '').replace(',', '.') || 0) *
                  parseFloat((element.qte + '').replace(',', '.') || 0));
          });
        }
      } else {
        if (this.facture.comment_calculer_facture !== 'montant') {
          this.facture?.produits?.forEach(element => {
            if (
              element.qte_apres != null &&
              element.pu_ht_apres != null &&
              element.reduction_apres != null
            ) {
              sum +=
                (parseFloat((element.qte_apres + '').replace(',', '.') || 0) *
                  (element.unite === '%'
                    ? parseFloat(
                        (element.pu_ht_apres + '').replace(',', '.') || 0
                      ) / 100
                    : parseFloat(
                        (element.pu_ht_apres + '').replace(',', '.') || 0
                      )) *
                  parseFloat(
                    (element.reduction_apres + '').replace(',', '.') || 0
                  )) /
                100;
            }
          });
        } else {
          this.facture?.produits?.forEach(element => {
            if (element.reduction_apres != null) {
              element.unite === '%'
                ? (sum +=
                    (parseFloat(
                      (element.reduction_apres + '').replace(',', '.') || 0
                    ) /
                      100) *
                    parseFloat((element.qte_apres + '').replace(',', '.') || 0))
                : (sum +=
                    parseFloat(
                      (element.reduction_apres + '').replace(',', '.') || 0
                    ) *
                    parseFloat(
                      (element.qte_apres + '').replace(',', '.') || 0
                    ));
            }
          });
        }
      }
      return sum;
    },
    computed_qte_sous_total_avoir() {
      return function(produit) {
        return Math.round((produit?.qte_apres - produit?.qte) * 100) / 100 || 0;
      };
    },
    computed_total_ht_sous_total_avoir() {
      return function(produit) {
        return (
          Math.round((produit?.total_ht_apres - produit?.total_ht) * 100) /
            100 || 0
        );
      };
    },
    computed_pu_ht_sous_total_avoir() {
      return function(produit) {
        return (
          Math.round((produit?.pu_ht_apres - produit?.pu_ht) * 100) / 100 || 0
        );
      };
    },
    computed_total_ttc_sous_total_avoir() {
      return function(produit) {
        return (
          Math.round((produit?.total_ttc_apres - produit?.total_ttc) * 100) /
            100 || 0
        );
      };
    }
    // computedcheckRolePermission() {
    //   if (
    //     this.getUserData &&
    //     this.getUserData.role &&
    //     this.getUserData.role == 'responsable_filiale'
    //   ) {
    //     return true;
    //   }
    //   return false;
    // }
  },
  methods: {
    ...mapActions([
      'getSettingFilialeTh',
      'addNewFactureLibreTh',
      'getFactureLibreNumber',
      'getAllCountreies',
      'getOneFacturesLibres',
      'updateFactureLibre',
      'fetchAllProducts',
      'fetchAllCategories',
      'getSettingFilialeThNew',
      'getAllFacturesForAvoir',
      'displayFacture',
      'addUniteTva',
      'getUnites',
      'getFilialsOfConnectedResponsable',
      'getTvas',
      'getCategories',
      'addCategorie',
      'getAllErrorsCreationFactureLibre',
      'getAllRibOfFiliale'
    ]),
    async addCat() {
      if (this.nom_categorie === '' || this.nom_categorie === null) {
        this.errorCategorie = 'ce champ et obligatoire';
        return;
      } else {
        this.loadingCategorie = true;
        const response = await this.addCategorie({
          nom_categorie: this.nom_categorie
        });
        if (response.success) {
          this.$refs['addCategorieModal'].hide();
          this.categorieList.push(response.response);
          this.resteCategorieModal();
          this.loadingCategorie = false;
        } else {
          // this.$refs['addCategorieModal'].hide();
          this.errorCategorie = response.error;
          // this.resteCategorieModal();
          this.loadingCategorie = false;
        }
      }
    },
    resteCategorieModal() {
      this.nom_categorie = null;
      this.errorCategorie = null;
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
    },
    async addTav(type) {
      if (this.valeur > 100) {
        this.errorTvaUnite = 'tva doit etre inferieur à 100';
      } else if (this.valeur === '' || this.valeur === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type
        });
        if (response.success) {
          this.$refs['addtvaModal'].hide();
          this.listTva.push(response.response.valeur);
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addtvaModal'].hide();
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        }
      }
    },
    async addUnite(type) {
      if (this.valeur === '' || this.valeur === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type
        });
        if (response.success) {
          this.$refs['addUnitModal'].hide();
          this.uniteList.push(response.response.valeur);
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addUnitModal'].hide();
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        }
      }
    },
    hideModal() {
      this.$refs['displayModal'].hide();
    },
    onChangeIbanVendeur() {
      this.facture.iban = this.facture.vendeur.iban;
    },
    onChangeSwiftVendeur() {
      this.facture.swift = this.facture.vendeur.swift;
    },
    filterNomFamilleAcheteur() {
      this.isOpenAcheteurParticulierNomFamilleList = true;
      this.resultsPrenomNomFamille = this.filialsListParticuluerNames.filter(
        item => {
          return item
            ?.toLowerCase()
            .startsWith(this.facture?.acheteur?.nom_famille?.toLowerCase());
        }
      );
    },
    setResultsNomFamilleAcheteur(result) {
      const newArray = result.split(' ');
      this.facture.acheteur.nom_famille = newArray[1];
      const currentAcheteur = this.particulierSocite.find(
        element =>
          element.prenom === newArray[0] && element.nom_famille === newArray[1]
      );
      this.facture.acheteur.adresse = currentAcheteur.adresse;
      this.facture.acheteur.prenom = currentAcheteur.prenom;
      this.facture.acheteur.telephone = currentAcheteur.telephone;
      this.facture.acheteur.telephone_portable =
        currentAcheteur.telephone_portable;
      this.facture.acheteur.nom_famille = currentAcheteur.nom_famille;
      this.facture.acheteur.pays = currentAcheteur.pays;
      this.facture.acheteur.ville = currentAcheteur.ville;
      this.facture.acheteur.civilite = currentAcheteur.civilite;
      this.facture.acheteur.code_postal = currentAcheteur.code_postal;
      this.facture.acheteur.email = currentAcheteur.email;
      this.facture.acheteur.siret = currentAcheteur.siret;
      this.facture.acheteur.banque = currentAcheteur.banque;
      this.facture.acheteur.swift = currentAcheteur.swift;
      this.facture.acheteur.description = currentAcheteur.description;
      this.facture.acheteur.siren_value = currentAcheteur.siren_value;
      this.facture.acheteur.tva_value = currentAcheteur.tva_value;
    },
    filterPrenomAcheteur() {
      this.isOpenAcheteurParticulierPrenomList = true;
      this.resultsPrenomNomFamille = this.filialsListParticuluerNames.filter(
        item => {
          return item
            ?.toLowerCase()
            .startsWith(this.facture?.acheteur?.prenom?.toLowerCase());
        }
      );
    },
    setResultsPrenomAcheteur(result) {
      const newArray = result.split(' ');
      this.facture.acheteur.prenom = newArray[0];
      const currentAcheteur = this.particulierSocite.find(
        element =>
          element.prenom === newArray[0] && element.nom_famille === newArray[1]
      );
      this.facture.acheteur.adresse = currentAcheteur.adresse;
      this.facture.acheteur.telephone = currentAcheteur.telephone;
      this.facture.acheteur.telephone_portable =
        currentAcheteur.telephone_portable;
      this.facture.acheteur.nom_famille = currentAcheteur.nom_famille;
      this.facture.acheteur.pays = currentAcheteur.pays;
      this.facture.acheteur.ville = currentAcheteur.ville;
      this.facture.acheteur.civilite = currentAcheteur.civilite;
      this.facture.acheteur.code_postal = currentAcheteur.code_postal;
      this.facture.acheteur.email = currentAcheteur.email;
      this.facture.acheteur.siret = currentAcheteur.siret;
      this.facture.acheteur.banque = currentAcheteur.banque;
      this.facture.acheteur.swift = currentAcheteur.swift;
      this.facture.acheteur.description = currentAcheteur.description;
      this.facture.acheteur.siren_value = currentAcheteur.siren_value;
      this.facture.acheteur.tva_value = currentAcheteur.tva_value;
    },
    filterResults() {
      this.isOpen = true;
      this.results = this.filialsListNames.filter(item => {
        return item
          ?.toLowerCase()
          .startsWith(this.facture?.acheteur?.nom_compagnie?.toLowerCase());
      });
    },
    onChangeGlobalReduction(reductionGlobal) {
      this.facture.produits.map(item => {
        item.reduction = reductionGlobal;
        if (this.facture.comment_calculer_facture === 'pourcentage') {
          item.total_ht =
            Math.round(
              (parseFloat((item.qte + '').replace(',', '.') || 0) *
                (item.unite === '%'
                  ? parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0)
                  : parseFloat((item.pu_ht + '').replace(',', '.') || 0)) -
                (parseFloat((item.qte + '').replace(',', '.') || 0) *
                  (item.unite === '%'
                    ? parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0)
                    : parseFloat((item.pu_ht + '').replace(',', '.') || 0)) *
                  parseFloat((item.reduction + '').replace(',', '.') || 0)) /
                  100) *
                100
            ) / 100;
          // variable tva utilser pour la calcule///////////////////////////
          let tva =
            Math.round(
              ((parseFloat((irem.total_ht + '').replace(',', '.') || 0) *
                parseFloat((item.tva + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
          // ///////////////////////////
          item.total_ttc_apres =
            Math.round(
              (parseFloat((item.total_ht + '').replace(',', '.') || 0) + tva) *
                100
            ) / 100;
        } else {
          item.total_ht =
            Math.round(
              parseFloat((item.qte + '').replace(',', '.') || 0) *
                (item.unite === '%'
                  ? parseFloat(
                      (item.pu_ht - item.reduction + '').replace(',', '.') || 0
                    ) / 100
                  : parseFloat(
                      (item.pu_ht - item.reduction + '').replace(',', '.') || 0
                    )) *
                100
            ) / 100;
          // variable tva utilser pour la calcule///////////////////////////
          let tva =
            Math.round(
              ((parseFloat((irem.total_ht + '').replace(',', '.') || 0) *
                parseFloat((item.tva + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
          // ///////////////////////////
          item.total_ttc_apres =
            Math.round(
              (parseFloat((item.total_ht + '').replace(',', '.') || 0) + tva) *
                100
            ) / 100;
        }
      });
    },

    onChangeCommentCalculer() {
      this.facture.produits.map(item => {
        if (this.facture.comment_calculer_facture === 'pourcentage') {
          item.total_ht =
            Math.round(
              (parseFloat((item.qte + '').replace(',', '.') || 0) *
                (item.unite === '%'
                  ? parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0)
                  : parseFloat((item.pu_ht + '').replace(',', '.') || 0)) -
                (parseFloat((item.qte + '').replace(',', '.') || 0) *
                  (item.unite === '%'
                    ? parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0)
                    : parseFloat((item.pu_ht + '').replace(',', '.') || 0)) *
                  parseFloat((item.reduction + '').replace(',', '.') || 0)) /
                  100) *
                100
            ) / 100;
          // variable tva utilser pour la calcule///////////////////////////
          let tva =
            Math.round(
              ((parseFloat((item.total_ht + '').replace(',', '.') || 0) *
                parseFloat((item.tva + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
          // ///////////////////////////
          item.total_ttc_apres =
            Math.round(
              (parseFloat((item.total_ht + '').replace(',', '.') || 0) + tva) *
                100
            ) / 100;
        } else {
          item.total_ht =
            Math.round(
              parseFloat((item.qte + '').replace(',', '.') || 0) *
                (item.unite === '%'
                  ? parseFloat(
                      (item.pu_ht - item.reduction + '').replace(',', '.') || 0
                    ) / 100
                  : parseFloat(
                      (item.pu_ht - item.reduction + '').replace(',', '.') || 0
                    )) *
                100
            ) / 100;

          // variable tva utilser pour la calcule///////////////////////////
          let tva =
            Math.round(
              ((parseFloat((item.total_ht + '').replace(',', '.') || 0) *
                parseFloat((item.tva + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
          // ///////////////////////////
          item.total_ttc_apres =
            Math.round(
              (parseFloat((item.total_ht + '').replace(',', '.') || 0) + tva) *
                100
            ) / 100;
        }
      });
    },
    onClickOutside() {
      this.isOpenAcheteurList = false;
      this.isOpenAcheteurParticulierPrenomList = false;
      this.isOpenAcheteurParticulierNomFamilleList = false;
    },
    filterResultsProduit(produit, index, type) {
      this.focusInput = index;
      if (type == 'apres') {
        this.facture.produits[index].isOpenProduitList_apres = true;
      } else {
        this.facture.produits[index].isOpenProduitList = true;
      }
      this.resultsProduits = this.productListName.filter(item => {
        return item?.toLowerCase().startsWith(produit.toLowerCase());
      });
    },
    onClickOutsideProduct() {
      if (this.focusInput != null) {
        this.facture.produits[this.focusInput].isOpenProduitList = false;
        this.facture.produits[this.focusInput].isOpenProduitList_apres = false;
        this.focusInput = null;
      }
    },
    setResultsProduct(result, produit) {
      produit.nom = result;
      produit.nom_apres = result;
      const currentProduit = this.getProduits.find(
        element => element.nom === result
      );
      produit.reference = currentProduit?.reference;
      produit.pu_ht = currentProduit.pu_ht;
      produit.tva = currentProduit.tva;
      produit.total_ttc =
        Math.round(
          (parseFloat((currentProduit.pu_ht + '').replace(',', '.') || 0) +
            (parseFloat((currentProduit.pu_ht + '').replace(',', '.') || 0) /
              100) *
              parseFloat((currentProduit.tva + '').replace(',', '.') || 0)) *
            parseFloat((produit.qte + '').replace(',', '.') || 0) *
            100
        ) / 100;
      produit.total_ht =
        Math.round(
          parseFloat((produit.qte + '').replace(',', '.') || 0) *
            parseFloat((currentProduit.pu_ht + '').replace(',', '.') || 0) *
            100
        ) / 100;

      produit.reference_apres = currentProduit?.reference;
      produit.pu_ht_apres = currentProduit.pu_ht;
      produit.tva_apres = currentProduit.tva;

      produit.total_ttc_apres =
        Math.round(
          (parseFloat((currentProduit.pu_ht + '').replace(',', '.') || 0) +
            (parseFloat((currentProduit.pu_ht + '').replace(',', '.') || 0) /
              100) *
              parseFloat((currentProduit.tva + '').replace(',', '.') || 0)) *
            parseFloat((produit.qte + '').replace(',', '.')) *
            100
        ) / 100;
      produit.total_ht_apres =
        Math.round(
          (parseFloat((produit.qte + '').replace(',', '.')) ||
            0 *
              parseFloat((currentProduit.pu_ht + '').replace(',', '.') || 0)) *
            100
        ) / 100;
    },
    setResults(result) {
      this.facture.acheteur.nom_compagnie = result;
      const currentAcheteur = this.getSettingFilialesTh.find(
        element => element.name === result
      );
      this.facture.acheteur.adresse = currentAcheteur.adresse;
      this.facture.acheteur.banque = currentAcheteur.banque;
      this.facture.acheteur.email = currentAcheteur.email;
      this.facture.acheteur.telephone = currentAcheteur.telephone;
      this.facture.acheteur.telephone_portable =
        currentAcheteur.telephone_portable;
      this.facture.acheteur.pays = currentAcheteur.pays;
      this.facture.acheteur.nom_famille = currentAcheteur.nom_famille;
      this.facture.acheteur.pays = currentAcheteur.pays;
      this.facture.acheteur.ville = currentAcheteur.ville;
      this.facture.acheteur.civilite = currentAcheteur.civilite;
      this.facture.acheteur.prenom = currentAcheteur.prenom;
      this.facture.acheteur.code_postal = currentAcheteur.code_postal;
      this.facture.acheteur.email = currentAcheteur.email;
      this.facture.acheteur.type_socite = currentAcheteur.type_socite;
      this.facture.acheteur.siren_value = currentAcheteur.siren_value;
      this.facture.acheteur.tva_value = currentAcheteur.tva_value;
      this.isOpen = false;
    },
    onClickOutsideNumDoc() {
      this.isOpenDocumentList = false;
    },
    filterResultsNumDoc() {
      this.isOpenDocumentList = true;
      this.resultsListAvoir = this.avoirListNums.filter(item => {
        return item
          ?.toLowerCase()
          .startsWith(this.facture?.numero_document_corrige.toLowerCase());
      });
    },
    async setResultsListAvoir(result) {
      this.facture.numero_document_corrige = result;
      this.disabledNumFactureAvoir = true;
      const idAvoir = this.getListFactureForAvoir.find(
        element => element.num === result
      )?.id;
      const response = await this.getOneFacturesLibres(idAvoir);
      if (response.success) {
        let fact = this.getFactureLibreToUpdate;

        fact.type = "Facture d'avoir";
        fact.isOpenProduitList = false;

        fact.isOpenProduitList_apres = false;
        fact.numero_document_corrige = this.facture.numero_document_corrige;
        fact.vendeur.id = this.getFactureLibreToUpdate.vendeur.filiale_id;
        fact.num = null;
        (fact.dateCreation = moment(Date.now()).format('DD-MM-YYYY')),
          (this.facture = fact);
        this.facture.produits = this.facture.produits.map(item => {
          if (item.type == 'produit') {
            return {
              ...item,
              contenu_apres: '',
              nom_apres: item.nom,
              reference_apres: item.reference,
              depot_apres: null,
              reduction_apres: item.reduction,
              qte_apres: item.qte,
              unite_apres: item.unite,
              tva_apres: item.tva,
              pu_ht_apres: item.pu_ht,
              total_ttc_apres: item.total_ttc,
              total_ht_apres: item.total_ht,
              isSelectInputTva_apres: false,
              isSelectInput_apres: true,
              index_apres: 0,
              isOpenProduitList_apres: false
            };
          }
          return item;
        });
        const response = await this.getFactureLibreNumber(
          this.getFactureLibreToUpdate.vendeur.filiale_id
        );
        if (response.success === true) {
          this.facture.num = response.response;
        }
      }
    },
    changeUnite(produit) {
      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht =
          Math.round(
            (parseFloat((produit.qte + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat((produit.pu_ht / 100 + '').replace(',', '.') || 0)
                : parseFloat((produit.pu_ht + '').replace(',', '.') || 0)) -
              (parseFloat((produit.qte + '').replace(',', '.') || 0) *
                (produit.unite === '%'
                  ? parseFloat(
                      (produit.pu_ht / 100 + '').replace(',', '.') || 0
                    )
                  : parseFloat((produit.pu_ht + '').replace(',', '.') || 0)) *
                parseFloat((produit.reduction + '').replace(',', '.') || 0)) /
                100) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.') || 0) *
              parseFloat((produit.tva + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;

        // ///////////////////////////
        produit.total_ttc =
          Math.round(
            (parseFloat((produit.total_ht + '').replace(',', '.') || 0) + tva) *
              100
          ) / 100;
      } else {
        produit.total_ht =
          Math.round(
            parseFloat((produit.qte + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat(
                    (produit.pu_ht - produit.reduction + '').replace(
                      ',',
                      '.'
                    ) || 0
                  ) / 100
                : parseFloat(
                    (produit.pu_ht - produit.reduction + '').replace(
                      ',',
                      '.'
                    ) || 0
                  )) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.') || 0) *
              parseFloat((produit.tva + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc =
          Math.round(
            (parseFloat((produit.total_ht + '').replace(',', '.') || 0) + tva) *
              100
          ) / 100;
      }
    },

    claculeTotal_Ht_Ttc(produit) {
      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht =
          Math.round(
            (parseFloat((produit.qte + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat((produit.pu_ht / 100 + '').replace(',', '.') || 0)
                : parseFloat((produit.pu_ht + '').replace(',', '.') || 0)) -
              (parseFloat((produit.qte + '').replace(',', '.') || 0) *
                (produit.unite === '%'
                  ? parseFloat(
                      (produit.pu_ht / 100 + '').replace(',', '.') || 0
                    )
                  : parseFloat((produit.pu_ht + '').replace(',', '.') || 0)) *
                parseFloat((produit.reduction + '').replace(',', '.') || 0)) /
                100) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.') || 0) *
              parseFloat((produit.tva + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc =
          Math.round(
            (parseFloat((produit.total_ht + '').replace(',', '.') || 0) + tva) *
              100
          ) / 100;
      } else {
        produit.total_ht =
          Math.round(
            parseFloat((produit.qte + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat(
                    (produit.pu_ht - produit.reduction + '').replace(
                      ',',
                      '.'
                    ) || 0
                  ) / 100
                : parseFloat(
                    (produit.pu_ht - produit.reduction + '').replace(
                      ',',
                      '.'
                    ) || 0
                  )) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.') || 0) *
              parseFloat((produit.tva + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc =
          Math.round(
            (parseFloat((produit.total_ht + '').replace(',', '.') || 0) + tva) *
              100
          ) / 100;
      }
    },
    change_reduction(produit) {
      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht =
          Math.round(
            (parseFloat((produit.qte + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat((produit.pu_ht + '').replace(',', '.') || 0) / 100
                : parseFloat((produit.pu_ht + '').replace(',', '.') || 0)) -
              (parseFloat((produit.qte + '').replace(',', '.') || 0) *
                (produit.unite === '%'
                  ? parseFloat((produit.pu_ht + '').replace(',', '.') || 0) /
                    100
                  : parseFloat((produit.pu_ht + '').replace(',', '.') || 0)) *
                parseFloat((produit.reduction + '').replace(',', '.') || 0)) /
                100) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.') || 0) *
              parseFloat((produit.tva + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc =
          Math.round(
            (parseFloat((produit.total_ht + '').replace(',', '.') || 0) + tva) *
              100
          ) / 100;
      } else {
        produit.total_ht =
          Math.round(
            parseFloat((produit.qte + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat(
                    (produit.pu_ht - produit.reduction + '').replace(
                      ',',
                      '.'
                    ) || 0
                  ) / 100
                : parseFloat(
                    (produit.pu_ht - produit.reduction + '').replace(
                      ',',
                      '.'
                    ) || 0
                  )) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.') || 0) *
              parseFloat((produit.tva + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc =
          Math.round(
            (parseFloat((produit.total_ht + '').replace(',', '.') || 0) + tva) *
              100
          ) / 100;
      }
    },
    change_reduction_apres(produit) {
      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht_apres =
          Math.round(
            (parseFloat((produit.qte_apres + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat(
                    (produit.pu_ht_apres + '').replace(',', '.') || 0
                  ) / 100
                : parseFloat(
                    (produit.pu_ht_apres + '').replace(',', '.') || 0
                  )) -
              (parseFloat((produit.qte_apres + '').replace(',', '.') || 0) *
                (produit.unite === '%'
                  ? parseFloat(
                      (produit.pu_ht_apres + '').replace(',', '.') || 0
                    ) / 100
                  : parseFloat(
                      (produit.pu_ht_apres + '').replace(',', '.') || 0
                    )) *
                parseFloat(
                  (produit.reduction_apres + '').replace(',', '.') || 0
                )) /
                100) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) *
              parseFloat((produit.tva_apres + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc_apres =
          Math.round(
            (parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) +
              tva) *
              100
          ) / 100;
      } else {
        produit.max_pu_ht =
          parseFloat(produit.net_ht_apres_avoir / produit.qte_apres) +
          parseFloat(produit.reduction_apres);
        produit.total_ht_apres =
          Math.round(
            parseFloat((produit.qte_apres + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat(
                    (
                      produit.pu_ht_apres -
                      produit.reduction_apres +
                      ''
                    ).replace(',', '.') || 0
                  ) / 100
                : parseFloat(
                    (
                      produit.pu_ht_apres -
                      produit.reduction_apres +
                      ''
                    ).replace(',', '.') || 0
                  )) *
              100
          ) / 100;
        //////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.') || 0) *
              parseFloat((produit.tva + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        ////////
        produit.total_ttc_apres =
          Math.round(
            (parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) +
              tva) *
              100
          ) / 100;
      }
    },

    claculeTotal_Ht_Ttc_apres(produit) {
      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht_apres =
          Math.round(
            (parseFloat((produit.qte_apres + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat(
                    (produit.pu_ht_apres + '').replace(',', '.') || 0
                  ) / 100
                : parseFloat(
                    (produit.pu_ht_apres + '').replace(',', '.') || 0
                  )) -
              (parseFloat((produit.qte_apres + '').replace(',', '.') || 0) *
                (produit.unite === '%'
                  ? parseFloat(
                      (produit.pu_ht_apres + '').replace(',', '.') || 0
                    ) / 100
                  : parseFloat(
                      (produit.pu_ht_apres + '').replace(',', '.') || 0
                    )) *
                parseFloat(
                  (produit.reduction_apres + '').replace(',', '.') || 0
                )) /
                100) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) *
              parseFloat((produit.tva_apres + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc_apres =
          Math.round(
            (parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) +
              tva) *
              100
          ) / 100;
      } else {
        produit.max_pu_ht =
          parseFloat(produit.net_ht_apres_avoir / produit.qte_apres) +
          parseFloat(produit.reduction_apres);
        produit.total_ht_apres =
          Math.round(
            parseFloat((produit.qte_apres + '').replace(',', '.') || 0) *
              (produit.unite === '%'
                ? parseFloat(
                    (
                      produit.pu_ht_apres -
                      produit.reduction_apres +
                      ''
                    ).replace(',', '.') || 0
                  ) / 100
                : parseFloat(
                    (
                      produit.pu_ht_apres -
                      produit.reduction_apres +
                      ''
                    ).replace(',', '.') || 0
                  )) *
              100
          ) / 100;
        // variable tva utilser pour la calcule///////////////////////////
        let tva =
          Math.round(
            ((parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) *
              parseFloat((produit.tva_apres + '').replace(',', '.') || 0)) /
              100) *
              100
          ) / 100;
        // ///////////////////////////
        produit.total_ttc_apres =
          Math.round(
            (parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) +
              tva) *
              100
          ) / 100;
      }
    },

    // onChangeTotalHt(produit) {
    //   produit.total_ttc =
    //     Math.round(
    //       (parseFloat((produit.pu_ht + '').replace(',', '.') || 0) +
    //         (parseFloat((produit.pu_ht + '').replace(',', '.') || 0) / 100) *
    //           parseFloat((produit.tva + '').replace(',', '.') || 0)) *
    //         parseFloat((produit.qte + '').replace(',', '.') || 0) *
    //         100
    //     ) / 100;
    //   produit.pu_ht =
    //     Math.round(
    //       (parseFloat((produit.total_ht + '').replace(',', '.') || 0) /
    //         parseFloat((produit.qte + '').replace(',', '.') || 0)) *
    //         100
    //     ) / 100;
    // },
    // changeTotalTtcApres(produit) {
    //   produit.total_ht_apres =
    //     Math.round(
    //       (parseFloat((produit.total_ttc_apres + '').replace(',', '.') || 0) /
    //         (100 +
    //           parseFloat((produit.tva_apres + '').replace(',', '.') || 0))) *
    //         100 *
    //         100
    //     ) / 100;
    //   produit.pu_ht_apres =
    //     Math.round(
    //       (parseFloat((produit.total_ht_apres + '').replace(',', '.') || 0) /
    //         parseFloat((produit.qte_apres + '').replace(',', '.') || 0)) *
    //         100
    //     ) / 100;
    // },
    // changeTotalTtc(produit) {
    //   produit.total_ht =
    //     Math.round(
    //       (parseFloat((produit.total_ttc + '').replace(',', '.') || 0) /
    //         (100 + parseFloat((produit.tva + '').replace(',', '.') || 0))) *
    //         100 *
    //         100
    //     ) / 100;
    //   produit.pu_ht =
    //     Math.round(
    //       (parseFloat((produit.total_ht + '').replace(',', '.') || 0) /
    //         parseFloat((produit.qte + '').replace(',', '.') || 0)) *
    //         100
    //     ) / 100;
    // },
    async vendeurSelected() {
      let item = this.getFiliaeOfResponsable.filter(
        element => element.id === this.facture.vendeur.idOrigine
      )[0];
      this.facture.vendeur.id = item.id;
      this.facture.vendeur.nom_compagnie = item.name;
      this.facture.vendeur.email = item.email;
      this.facture.vendeur.adresse = item.adresse;
      this.facture.vendeur.iban = item.iban;
      this.facture.vendeur.swift = item.swift;
      this.facture.vendeur.iban = item.iban;
      this.facture.vendeur.site_internet = item.site_internet;
      this.facture.vendeur.swift = item.swift;
      this.facture.vendeur.pays = item.pays;
      this.facture.vendeur.siren_tva_value = item.siren_tva_value;
      this.facture.vendeur.fax = item.fax;
      this.facture.vendeur.siren_value = item.siren_value;
      this.facture.vendeur.tva_value = item.tva_value;
      this.facture.vendeur.telephone = item.telephone;
      this.facture.vendeur.code_postal = item.code_postal;
      this.facture.vendeur.type_socite = item.type_socite;
      this.facture.vendeur.ville = item.ville;
      const response = await this.getFactureLibreNumber(
        this.facture.vendeur.id
      );
      await this.getAllRibOfFiliale(item.id);
      this.getRibOfFilials.map(item => {
        if (item.favori === true) {
          this.rib = item;
          this.facture.iban = item?.iban;
          this.facture.swift = item?.swift;
        }
      });
      if (response.success === true) {
        this.facture.num = response.response;
      }
      if (this.facture.type !== null && this.facture.date_creation !== null) {
        const res = await this.getAllErrorsCreationFactureLibre({
          type: this.facture.type,
          id: item.id,
          date_creation: this.facture.date_creation
        });
        if (!res.success) {
          this.$swal.fire({
            // title: res.error,
            background: 'rgb(245 245 252)',
            type: 'error',
            icon: 'error',
            allowOutsideClick: false,
            showCloseButton: false,
            showConfirmButton: false,
            html:
              '<h5 id="text-error"><b>' +
              res.error +
              '</b></h5>' +
              " <p><centre> Veuillez contacter l'administrateur pour apporter les corrections nécessaires</centre></p>" +
              '<br>' +
              '<centre style="display:flex;justify-content:space-around"><button type="button" role="button" id="SwalBtn" tabindex="0" class="SwalBtn1 customSwalBtn" style=" border: 0;outline: 0;padding: 10px;border-radius: 9px;color: white;background: #f8be40;">' +
              'Essayer de nouveau' +
              '<centre></button>',
            willOpen: () => {
              document
                .getElementById('SwalBtn')
                .addEventListener('click', async () => {
                  document.getElementById('SwalBtn').innerHTML =
                    'Essayer de nouveau' + '<div>Chargement ...</div>';
                  const response2 = await this.getAllErrorsCreationFactureLibre(
                    {
                      type: this?.facture?.type,
                      id: item.id,
                      date_creation: this?.facture?.date_creation
                    }
                  );
                  if (response2.success) {
                    this.$swal.close();
                  } else {
                    document.getElementById('text-error').innerHTML =
                      response2.error;
                    document.getElementById('SwalBtn').innerHTML =
                      'Essayer de nouveau';
                  }
                });
            }
          });
        }
      }
    },
    async getErrorsOfCreatFacture() {
      // const obj = {
      //   type: this.facture.type,
      //   id: this.facture.vendeur.id,
      //   date_creation: this.facture.date_creation
      // };
      if (
        this?.facture?.vendeur?.id !== undefined &&
        this?.facture?.vendeur?.id !== null &&
        this.facture.date_creation !== null
      ) {
        const res = await this.getAllErrorsCreationFactureLibre({
          type: this.facture.type,
          id: this?.facture?.vendeur?.id,
          date_creation: this.facture.date_creation
        });
        if (!res.success) {
          this.$swal.fire({
            // title: res.error,
            background: 'rgb(245 245 252)',
            type: 'error',
            icon: 'error',
            allowOutsideClick: false,
            showCloseButton: false,
            showConfirmButton: false,
            html:
              '<h5 id="text-error"><b>' +
              res.error +
              '</b></h5>' +
              " <p><centre> Veuillez contacter l'administrateur pour apporter les corrections nécessaires</centre></p>" +
              '<br>' +
              '<centre style="display:flex;justify-content:space-around"><button type="button" role="button" id="SwalBtn" tabindex="0" class="SwalBtn1 customSwalBtn" style=" border: 0;outline: 0;padding: 10px;border-radius: 9px;color: white;background: #f8be40;">' +
              'Essayer de nouveau' +
              '<centre></button>',
            willOpen: () => {
              document
                .getElementById('SwalBtn')
                .addEventListener('click', async () => {
                  document.getElementById('SwalBtn').innerHTML =
                    'Essayer de nouveau' + '<div>Chargement ...</div>';
                  const response2 = await this.getAllErrorsCreationFactureLibre(
                    {
                      type: this?.facture?.type,
                      id: this?.facture?.vendeur?.id,
                      date_creation: this?.facture?.date_creation
                    }
                  );
                  if (response2.success) {
                    this.$swal.close();
                  } else {
                    document.getElementById('text-error').innerHTML =
                      response2.error;
                    document.getElementById('SwalBtn').innerHTML =
                      'Essayer de nouveau';
                  }
                });
            }
          });
        }
      }
    },
    onChangeRibSelect(e) {
      this.facture.iban = e?.iban;
      this.facture.swift = e?.swift;
    },
    showMoreVendeur() {
      this.hideVendeur = !this.hideVendeur;
    },
    showMoreAcheteur() {
      this.hideAcheteur = !this.hideAcheteur;
    },
    showReduction() {
      this.showReduc = true;
    },
    showDepot() {
      this.showDep = true;
    },
    hideReduction() {
      this.showReduc = false;
    },
    hideDepot() {
      this.showDep = false;
    },
    addNewProduct() {
      this.facture.produits.push({
        contenu: '',
        nom: null,
        reference: null,
        depot: null,
        reduction: 0,
        qte: 1,
        unite: null,
        tva: 20,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        description: null,
        isSelectInputTva: false,
        isSelectInput: true,
        index: 0,
        type: 'produit',
        isOpenProduitList: false,
        contenu_apres: '',
        nom_apres: null,
        reference_apres: null,
        depot_apres: null,
        reduction_apres: 0,
        qte_apres: 1,
        unite_apres: ' ',
        tva_apres: 0,
        pu_ht_apres: 0,
        total_ttc_apres: 0,
        total_ht_apres: 0,
        isSelectInputTva_apres: false,
        isSelectInput_apres: true,
        index_apres: 0,
        isOpenProduitList_apres: false,
        qte_sous_total_avoir: 0,
        pu_ht_sous_total_avoir: 0,
        total_ht_sous_total_avoir: 0,
        total_ttc_sous_total_avoir: 0
      });
    },
    addNewSousToltal() {
      this.facture.produits.push({
        qte: 0,
        unite: null,
        reduction: 0,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'sous_total',
        contenu: '',
        index: 0
      });
    },
    addNewSautPage() {
      this.facture.produits.push({
        qte: 0,
        reduction: 0,
        unite: null,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'saut_page',
        contenu: '',
        index: 0,
        contenu: ''
      });
    },
    addNewTextLine() {
      this.facture.produits.push({
        qte: 0,
        unite: null,
        reduction: 0,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'ligne_text',
        contenu: '',
        index: 0
      });
    },
    deleteProduct(index) {
      this.facture.produits.splice(index, 1);
    },
    async submitFactureLibre() {
      this.loading = true;
      this.facture.famille = 'libre';
      const response = await this.addNewFactureLibreTh(this.facture);
      if (response.success === true) {
        this.$router.push(`/display-facture/${response.response.id}`);
        this.loading = false;
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async displayBill() {
      this.loading = true;
      const response = await this.displayFacture(this.facture);
      if (response.success === true) {
        this.loading = false;
        this.pdfSource = response.response;
      } else {
        this.error = response.error;
        this.loading = false;
      }
    }
  },

  async mounted() {
    this.loading = true;
    // if (
    //   this.getUserData &&
    //   this.getUserData.role &&
    //   this.getUserData.role == 'responsable_filiale'
    // ) {
    //   await this.getSettingFilialeThNew();
    //   await this.getFilialsOfConnectedResponsable();
    // } else {
    //   await this.getSettingFilialeTh();
    // }
    // if (
    //   this.getUserData &&
    //   this.getUserData.role &&
    //   this.getUserData.role == 'responsable_filiale'
    // ) {
    //   this.professionnelSociteVender = this.getFiliaeOfResponsable;
    // } else {
    //   this.professionnelSociteVender = this.getSettingFilialesTh.filter(
    //     element => element.type === 'professionnel'
    //   );
    // }
    await this.getSettingFilialeTh();
    await this.getFilialsOfConnectedResponsable();
    this.professionnelSocite = this.getSettingFilialesTh.filter(
      element => element.type !== 'particulier'
    );
    this.particulierSocite = this.getSettingFilialesTh.filter(
      element => element.type === 'particulier'
    );
    this.professionnelSocite?.map(item =>
      this.filialsListNames?.push(item.name)
    );
    this.particulierSocite?.map(item =>
      this.filialsListParticuluerNames?.push(
        item.prenom + ' ' + item.nom_famille
      )
    );

    await this.getUnites();
    await this.getTvas();
    await this.getCategories();
    this.listTva = this.getAllTvas;
    this.uniteList = this.getAllUnites;
    this.categorieList = this.getAllCategories;
    await this.getAllCountreies();
    const response = await this.getOneFacturesLibres(this.$route?.params?.id);
    if (response.success === true) {
      this.facture = this.getFactureLibreToUpdate;
      this.facture.objet = null;
      this.facture.vendeur.idOrigine = this.getSettingFilialesTh?.find(
        element =>
          element.name === this.getFactureLibreToUpdate.vendeur.nom_compagnie
      )?.id;
      await this.getAllRibOfFiliale(this.facture.vendeur.idOrigine);
      this.getRibOfFilials.map(item => {
        if (item.favori === true) {
          this.rib = item;
          this.facture.iban = item?.iban;
          this.facture.swift = item?.swift;
        }
      });
      this.facture.categorie_id = this.getFactureLibreToUpdate?.categorie?.id;

      this.loading = false;
      await this.fetchAllProducts();
      await this.fetchAllCategories();
      let countryNames = [];
      this.getPaysList.map(element => countryNames.push(element.nom));
      this.uniqueArrayOfCountries = [...new Set(countryNames)];
      this.getProduits.map(item => this.productListName.push(item.nom));
      this.facture.acheteur.siren_tva = 'Numéro TVA';
      this.facture.vendeur.siren_tva = 'Numéro TVA';
    } else {
      this.error = response.error;
      this.$alert('', this.error, 'error').then(() => {
        this.$router.push('/facture-libre');
      });
      this.loading = false;
    }

    this.facture?.produits?.forEach(element => {
      if (element.reduction != '0') {
        this.showReduc = true;
      }
      return;
    });
  }
};
</script>
<style lang="scss" scoped>
* {
  font-family: 'Montserrat', sans-serif;
}
.body-box-rapport {
  height: calc(100vh - 68px);
  .tabs-body-style {
    width: 220px;
    .tab-item-style {
      display: inline;
      .title-tab-item {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 500;
        color: #5d5d5d;
      }
      .ligne {
        width: 100%;
        margin: auto;
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }
  }
  .display-tabs {
    display: none;
  }
  .table-rapport-style {
    .table {
      height: calc(100% - 50px);
    }
    .b-table-sticky-header {
      max-height: calc(100vh - 175px) !important;
      height: calc(100vh - 175px);
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  .width-table-rapport {
    width: 100%;
  }
}
#filiale-box-body::-webkit-scrollbar,
#filiale-body::-webkit-scrollbar {
  width: 7px;
}
#filiale-box-body::-webkit-scrollbar-track,
#filiale-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
#filiale-box-body::-webkit-scrollbar-thumb,
#filiale-body::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contenu-tab-users {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
}
.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}
.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
.color_picker_width {
  width: 25px;
}
.padding-input-number {
  padding: 5px;
}
.td-width {
  width: 150px;
}
</style>
<style lang="scss">
.d-block {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.actionModel {
  text-align: center;

  .button-cancel-style {
    width: 100px;
  }
}
.more_less {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #428bca;
  font-size: 13px;
  float: right;
  width: 100px;
  margin: 0;
}
.more_less:hover {
  text-decoration: underline;
}
.scroll-bar {
  overflow-y: scroll;
  padding: 10px;
}
.aligne {
  flex-direction: row;
  display: flex;
}
.background-style {
  background-color: #fff;
  border-radius: 25px;
}
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}

.list_satyle {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  width: 250px;
  height: auto;
  max-height: 200px;
}
.text-area-style {
  height: 25px;
}
.button-13 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: 'Amazon Ember', sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  outline: 0;
}

.list_style_products {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  width: 180px;
  height: auto;
  max-height: 125px;
}
.text-line {
  line-height: 0.5;
}
.button-produit {
  background-color: #adb5bd;
}
.list_satyle_prenom {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  width: 170px;
  height: auto;
  max-height: 200px;
}
</style>
<style lang="scss">
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
